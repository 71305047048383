const mapState = {
  stateJalan: false,
  jalanNsl: false,
  batubara: false,
  mineralNonLogam: false,
  batasDes: false,
  kawasanHtn: false,
  stateArenaOlahraga: false,
  stateAgrisawah: false,
  stateKantorPLN: false,
  stateBangunanPT: false,
};

export const MapReducer = (state = mapState, action) => {
  if (action.type === "MAP_JALAN") {
    return {
      ...state,
      stateJalan: action.value,
    };
  }
  if (action.type === "MAP_AGRISAWAH") {
    return {
      ...state,
      stateAgrisawah: action.value,
    };
  }
  if (action.type === "MAP_KANTOR_PLN") {
    return {
      ...state,
      stateKantorPLN: action.value,
    };
  }
  if (action.type === "MAP_BANGUNAN_PT") {
    return {
      ...state,
      stateBangunanPT: action.value,
    };
  }
  if (action.type === "ARENA_OLAHRAGA") {
    return {
      ...state,
      stateArenaOlahraga: action.value,
    };
  }
  if (action.type === "MAP_JALAN_NASIONAL") {
    return {
      ...state,
      jalanNsl: action.value,
    };
  }
  if (action.type === "MAP_BATUBARA") {
    return {
      ...state,
      batubara: action.value,
    };
  }
  if (action.type === "MAP_MINERAL_NON_LOGAM") {
    return {
      ...state,
      mineralNonLogam: action.value,
    };
  }
  if (action.type === "MAP_BATAS_DESA") {
    return {
      ...state,
      batasDes: action.value,
    };
  }
  if (action.type === "MAP_KAWASAN_HUTAN") {
    return {
      ...state,
      kawasanHtn: action.value,
    };
  }
  return state;
};
