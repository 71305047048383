import React from "react";
import { Polyline } from "react-leaflet";
import { useSelector } from "react-redux";
import agrisawah from "../../../data/agrisawah.json";

const Agrisawah = () => {
  const { stateAgrisawah } = useSelector((state) => state);
  return (
    <>
      {stateAgrisawah
        ? agrisawah.geometries.map((item1, index) => {
            const coordinates = item1.coordinates[0].map((item2) => [
              item2[1],
              item2[0],
            ]);
            return (
              <Polyline
                key={index}
                pathOptions={{
                  color: "white",
                  opacity: 1,
                  weight: 2,
                  dashArray: 3,
                }}
                positions={coordinates}
              />
            );
          })
        : null}
    </>
  );
};

export default Agrisawah;
