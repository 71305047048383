import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoPNG } from "../../../assets";

const Sidebar = () => {
  const dispatch = useDispatch();
  const {
    stateArenaOlahraga,
    stateJalan,
    jalanNsl,
    batubara,
    mineralNonLogam,
    batasDes,
    kawasanHtn,
    stateAgrisawah,
    stateBangunanPT,
    stateKantorPLN,
  } = useSelector((state) => state);
  return (
    <>
      <div
        style={{
          position: "absolute",
          left: -2,
          top: "40%",
          bottom: "60%",
          height: 20,
          zIndex: 999,
        }}
      >
        <button
          className="btn btn-light py-4 px-1"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
          style={{
            borderTopRightRadius: 15,
            borderBottomRightRadius: 15,
          }}
        >
          <i className="fa-solid fa-angle-right fa-fw"></i>
        </button>
      </div>

      <div
        className="offcanvas offcanvas-start"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <div className="d-block">
            <img src={logoPNG} className="img-fluid" width="90" alt="" />
          </div>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div
            style={{
              position: "absolute",
              right: "-7%",
              top: "40%",
              bottom: "60%",
              zIndex: 9999,
            }}
          >
            <button
              type="button"
              className="btn bg-white py-4 px-2"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              style={{
                borderTopRightRadius: 15,
                borderBottomRightRadius: 15,
              }}
            >
              <i className="fa-solid fa-angle-left fa-fw"></i>
            </button>
          </div>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control py-3"
              placeholder="Telusuri Satu Peta Tapin"
            />
            <span className="input-group-text bg-white" id="basic-addon2">
              <i className="fas fa-search fa-fw"></i>
            </span>
          </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => {
                      stateAgrisawah
                        ? dispatch({ type: "MAP_AGRISAWAH", value: false })
                        : dispatch({ type: "MAP_AGRISAWAH", value: true });
                    }}
                  />
                  Agrisawah
                </label>
              </div>
            </li>
            <li className="list-group-item">
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => {
                      stateArenaOlahraga
                        ? dispatch({ type: "ARENA_OLAHRAGA", value: false })
                        : dispatch({ type: "ARENA_OLAHRAGA", value: true });
                    }}
                  />
                  Arena Olahrga
                </label>
              </div>
            </li>
            <li className="list-group-item">
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => {
                      stateBangunanPT
                        ? dispatch({ type: "MAP_BANGUNAN_PT", value: false })
                        : dispatch({ type: "MAP_BANGUNAN_PT", value: true });
                    }}
                  />
                  Bangunan PT
                </label>
              </div>
            </li>
            <li className="list-group-item">
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => {
                      stateJalan
                        ? dispatch({ type: "MAP_JALAN", value: false })
                        : dispatch({ type: "MAP_JALAN", value: true });
                    }}
                  />
                  Jalan
                </label>
              </div>
            </li>
            <li className="list-group-item">
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => {
                      jalanNsl
                        ? dispatch({ type: "MAP_JALAN_NASIONAL", value: false })
                        : dispatch({ type: "MAP_JALAN_NASIONAL", value: true });
                    }}
                  />
                  Jalan Nasional
                </label>
              </div>
            </li>
            <li className="list-group-item">
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => {
                      stateKantorPLN
                        ? dispatch({ type: "MAP_KANTOR_PLN", value: false })
                        : dispatch({ type: "MAP_KANTOR_PLN", value: true });
                    }}
                  />
                  Kantor PLN
                </label>
              </div>
            </li>
            <li className="list-group-item">
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => {
                      kawasanHtn
                        ? dispatch({ type: "MAP_KAWASAN_HUTAN", value: false })
                        : dispatch({ type: "MAP_KAWASAN_HUTAN", value: true });
                    }}
                  />
                  Penunjang Kawasan Hutan
                </label>
              </div>
            </li>
            <li className="list-group-item">
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => {
                      batubara
                        ? dispatch({ type: "MAP_BATUBARA", value: false })
                        : dispatch({ type: "MAP_BATUBARA", value: true });
                    }}
                  />
                  Sumber Daya Batubara
                </label>
              </div>
            </li>
            <li className="list-group-item">
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => {
                      mineralNonLogam
                        ? dispatch({
                            type: "MAP_MINERAL_NON_LOGAM",
                            value: false,
                          })
                        : dispatch({
                            type: "MAP_MINERAL_NON_LOGAM",
                            value: true,
                          });
                    }}
                  />
                  Sumber Daya Mineral Non Logam
                </label>
              </div>
            </li>
            <li className="list-group-item">
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => {
                      batasDes
                        ? dispatch({ type: "MAP_BATAS_DESA", value: false })
                        : dispatch({ type: "MAP_BATAS_DESA", value: true });
                    }}
                  />
                  Batas Desa
                </label>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
