import React from "react";
import { Polyline } from "react-leaflet";
import { useSelector } from "react-redux";
import jalan from "../../../data/jalan.json";

const Jalan = () => {
  const { stateJalan } = useSelector((state) => state);
  return (
    <>
      {stateJalan
        ? jalan.geometries.map((item1, index) => {
            const coordinates = item1.coordinates.map((item2) => [
              item2[1],
              item2[0],
            ]);
            return (
              <Polyline
                key={index}
                pathOptions={{
                  color: "white",
                  opacity: 1,
                  weight: 2,
                  dashArray: 3,
                }}
                positions={coordinates}
              />
            );
          })
        : null}
    </>
  );
};

export default Jalan;
