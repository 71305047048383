import { icon } from "leaflet";
import React from "react";
import { Marker, Popup } from "react-leaflet";
import { useSelector } from "react-redux";
import { iconBatubara } from "../../../assets";
import sumber_daya_batubara from "../../../data/sumber_daya_batubara.json";

const Batubara = () => {
  const ICON = icon({
    iconUrl: iconBatubara,
    iconSize: [32, 32],
  });

  const { batubara } = useSelector((state) => state);

  return (
    <>
      {batubara
        ? sumber_daya_batubara.features.map((item, index) => (
            <Marker
              key={index}
              icon={ICON}
              position={[
                item.geometry.coordinates[1],
                item.geometry.coordinates[0],
              ]}
            >
              <Popup>
                Nama: <b>{item.properties.namobj}</b>
                <br />
                Lokasi: <b>{item.properties.lokasi}</b>
                <br />
                Acuan: <b>{item.properties.acuan}</b>
              </Popup>
            </Marker>
          ))
        : null}
    </>
  );
};

export default Batubara;
