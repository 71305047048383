import React from "react";
import { GeoJSON } from "react-leaflet";
import jalan_nasional from "../../../data/jalan_nasional.json";
import { useSelector } from "react-redux";

const JalanNasional = () => {
  const { jalanNsl } = useSelector((state) => state);

  return jalanNsl ? (
    <GeoJSON
      style={{
        color: "#e9ff45",
      }}
      attribution="&copy; credits due..."
      data={jalan_nasional}
    />
  ) : null;
};

export default JalanNasional;
