import React from "react";
import { GeoJSON } from "react-leaflet";
import penunjang_kawasan_hutan from "../../../data/penunjukan_kawasan_hutan.json";
import { useSelector } from "react-redux";

const KawasanHutan = () => {
  const { kawasanHtn } = useSelector((state) => state);
  return kawasanHtn ? (
    <GeoJSON
      style={{
        color: "#1ae854",
      }}
      data={penunjang_kawasan_hutan}
    />
  ) : null;
};

export default KawasanHutan;
