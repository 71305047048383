import "leaflet/dist/leaflet.css";
import React from "react";
import { GeoJSON, LayersControl, MapContainer, TileLayer } from "react-leaflet";
import {
  Agrisawah,
  ArenaOlahraga,
  BangunanPT,
  BatasDesa,
  Batubara,
  Jalan,
  JalanNasional,
  KantorPLN,
  KawasanHutan,
  MineralNonLogam,
  Navbar,
  Sidebar,
} from "../../components";
import penunjang_kawasan_hutan from "../../data/penunjukan_kawasan_hutan.json";

const Map = () => {
  const position = [-2.936696834689908, 115.14971582813816];

  return (
    <>
      <Sidebar />
      <Navbar />
      <MapContainer
        center={position}
        zoom={10}
        style={{ width: "100%", height: "100vh" }}
        scrollWheelZoom={true}
        minZoom={11}
        fadeAnimation
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">Dinas Komunikasi dan Informatika Tapin</a> contributors'
          url="https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
        />
        <LayersControl position="topright">
          <LayersControl.Overlay name="Street Map">
            <TileLayer
              attribution=""
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.Overlay>
        </LayersControl>
        <Jalan />
        <JalanNasional />
        <KantorPLN />
        <Batubara />
        <MineralNonLogam />
        <BangunanPT />
        <BatasDesa />
        <KawasanHutan />
        <Agrisawah />
        <ArenaOlahraga />
        <GeoJSON
          style={{
            fillColor: "rgba(0,0,0,0.1)",
            color: "#4d9ae8",
          }}
          data={penunjang_kawasan_hutan}
        />
      </MapContainer>
      <div className="bg-white fixed-bottom text-end p-0 shadow-lg rounded-4">
        <small
          className=" p-0 m-0 mx-4"
          style={{
            fontSize: 10,
          }}
        >
          &copy; Dinas Komunikasi dan Informatika Kabupaten Tapin, with 💕
        </small>
      </div>
    </>
  );
};

export default Map;
