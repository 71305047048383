import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoPNG } from "../../../assets";

const Navbar = () => {
  const { batasDes, batubara, mineralNonLogam } = useSelector((state) => state);
  const dispatch = useDispatch();
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-transaprent fixed-bottom mb-3">
      <div className="container-fluid">
        <a className="navbar-brand text-white " href="javascrypt:;">
          <img src={logoPNG} className="img-fluid" width="80" alt="" />
        </a>
        <button
          className="navbar-toggler bg-white rounded-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-center"
          id="navbarNavAltMarkup"
        >
          <div className="navbar-nav">
            <button
              className={`nav-link btn btn-sm my-1 bg-${
                batasDes ? "primary" : "white"
              } text-${batasDes ? "white" : "dark"} rounded-pill px-4 me-2`}
              onClick={() => {
                batasDes
                  ? dispatch({ type: "MAP_BATAS_DESA", value: false })
                  : dispatch({ type: "MAP_BATAS_DESA", value: true });
              }}
            >
              Batas Desa
            </button>
            <button
              className={`nav-link btn btn-sm my-1 bg-${
                batubara ? "primary" : "white"
              } text-${batubara ? "white" : "dark"} rounded-pill px-4 me-2`}
              onClick={() => {
                batubara
                  ? dispatch({ type: "MAP_BATUBARA", value: false })
                  : dispatch({ type: "MAP_BATUBARA", value: true });
              }}
            >
              Sumber Daya Batubara
            </button>
            <button
              className={`nav-link btn btn-sm my-1 bg-${
                mineralNonLogam ? "primary" : "white"
              } text-${
                mineralNonLogam ? "white" : "dark"
              } rounded-pill px-4 me-2`}
              onClick={() => {
                mineralNonLogam
                  ? dispatch({
                      type: "MAP_MINERAL_NON_LOGAM",
                      value: false,
                    })
                  : dispatch({
                      type: "MAP_MINERAL_NON_LOGAM",
                      value: true,
                    });
              }}
            >
              Sumber Daya Mineral Non Logam
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
