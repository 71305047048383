import React from "react";
import rtrwk from "../../../data/rtrwk.json";
import { useSelector } from "react-redux";
import { GeoJSON } from "react-leaflet";

const BatasDesa = () => {
  const { batasDes } = useSelector((state) => state);
  return batasDes ? (
    <GeoJSON
      style={{
        color: "#ed6b6b",
      }}
      data={rtrwk}
    />
  ) : null;
};

export default BatasDesa;
