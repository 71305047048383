import { icon } from "leaflet";
import React from "react";
import { Marker, Popup } from "react-leaflet";
import { useSelector } from "react-redux";
import { iconStone } from "../../../assets";
import mineral_non_logam from "../../../data/peta_sumber_daya_mineral_non_logam.json";

const MineralNonLogam = () => {
  const ICON = icon({
    iconUrl: iconStone,
    iconSize: [32, 32],
  });
  const { mineralNonLogam } = useSelector((state) => state);
  return (
    <>
      {mineralNonLogam
        ? mineral_non_logam.features.map((item, index) => (
            <Marker
              key={index}
              icon={ICON}
              position={[
                item.geometry.coordinates[1],
                item.geometry.coordinates[0],
              ]}
            >
              <Popup>
                Jenis: <b>{item.properties.jnskombl}</b>
                <br />
                Lokasi: <b>{item.properties.lokasibl}</b>
                <br />
                Acuan: <b>{item.properties.acuan}</b>
              </Popup>
            </Marker>
          ))
        : null}
    </>
  );
};

export default MineralNonLogam;
