import { icon } from "leaflet";
import React from "react";
import { Marker } from "react-leaflet";
import { useSelector } from "react-redux";
import { iconPLN } from "../../../assets";
import kantorPLN from "../../../data/kantor_pln.json";

const KantorPLN = () => {
  const ICON = icon({
    iconUrl: iconPLN,
    iconSize: [32, 32],
  });
  const { stateKantorPLN } = useSelector((state) => state);
  return (
    <>
      {stateKantorPLN
        ? kantorPLN.geometries.map((item, index) => (
            <Marker
              key={index}
              icon={ICON}
              position={[
                item.coordinates[1],
                item.coordinates[0],
              ]}
            >
            </Marker>
          ))
        : null}
    </>
  );
};

export default KantorPLN;
