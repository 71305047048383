import { icon } from "leaflet";
import React from "react";
import { Marker } from "react-leaflet";
import { useSelector } from "react-redux";
import { iconBuilding } from "../../../assets";
import bangunanPT from "../../../data/bangunan_pt.json";

const BangunanPT = () => {
  const ICON = icon({
    iconUrl: iconBuilding,
    iconSize: [32, 32],
  });
  const { stateBangunanPT } = useSelector((state) => state);
  return (
    <>
      {stateBangunanPT
        ? bangunanPT.geometries.map((item, index) => (
            <Marker
              key={index}
              icon={ICON}
              position={[
                item.coordinates[1],
                item.coordinates[0],
              ]}
            >
            </Marker>
          ))
        : null}
    </>
  );
};

export default BangunanPT;
